import { combineReducers } from "redux";

// reducer import
import coreReducer from "./reducers/core";
import exploreReducer from "./reducers/explore";
import dashboardReducer from "./reducers/dashboard";
import exportReducer from "./reducers/export";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  core: coreReducer,
  explore: exploreReducer,
  dashboard: dashboardReducer,
  export: exportReducer,
});

export default reducer;
