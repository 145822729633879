import { lazy } from "react";

import { useRoutes } from "react-router-dom";

// project imports
// import MainLayout from 'layout/MainLayout';
import Base from "./Base";
import Loadable from "./Utils/Loadable";

const ExploreDefault = Loadable(lazy(() => import("./Explore/Explore")));
// dashboard routing
const DashboardDefault = Loadable(lazy(() => import("./Dashboard/Dashboard")));

const ExportDefault = Loadable(lazy(() => import("./Export/Export")));

const InfoDefault = Loadable(lazy(() => import("./Info/Info")));
// const
// utilities routing
// const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
// const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
// const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
// const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
// const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
// const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

// const AuthenticationRoutes = {
//     path: '/',
//     element: <MinimalLayout />,
//     children: [
//         {
//             path: '/pages/login/login3',
//             element: <AuthLogin3 />
//         },
//         {
//             path: '/pages/register/register3',
//             element: <AuthRegister3 />
//         }
//     ]
// };

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = [
  {
    path: "/",
    element: <Base />,
    children: [
      {
        path: "/",
        element: <ExploreDefault />,
      },
      {
        path: "/explore",
        element: <ExploreDefault />,
      },
      {
        path: "/dashboard",
        element: <DashboardDefault />,
      },
      {
        path: "/export",
        element: <ExportDefault />,
      },
      {
        path: "/info",
        element: <InfoDefault />,
      },
      // {
      //     path: '/utils/util-color',
      //     element: <UtilsColor />
      // },
      // {
      //     path: '/utils/util-shadow',
      //     element: <UtilsShadow />
      // },
      // {
      //     path: '/icons/tabler-icons',
      //     element: <UtilsTablerIcons />
      // },
      // {
      //     path: '/icons/material-icons',
      //     element: <UtilsMaterialIcons />
      // },
      // {
      //     path: '/sample-page',
      //     element: <SamplePage />
      // }
    ],
  },
];

// ==============================|| ROUTING RENDER ||============================== //

export default function Routes() {
  return useRoutes(MainRoutes);
}
